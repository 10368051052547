import styled from "styled-components"

export const MainComponent = styled.main`
  display: block;
  font-family: Roboto, sans-serif, serif, -apple-system;
  -webkit-font-smoothing:antialiased;
  font-size: 16px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`

export const H2Section = styled.h2`
  font-size: 2em;
  font-weight: 700;
  color: #333;
  text-transform: uppercase;
  text-align: center;

  @media screen and (min-width: 1280px) {
    font-size: 3em;
    text-align: left;
  }
`